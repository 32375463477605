module.exports = {
  siteTitle: 'Wang Shaopeng\'s Website', // <title>
  manifestName: 'Resume',
  manifestShortName: 'Landing',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/avatar.png',
  pathPrefix: `/Wang-Shaopeng-resume/`,
  firstName: 'Shaopeng',
  lastName: 'Wang',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/shaopeng-sp',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/shaopeng-wang/',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/shaopeng_w/',
    },
  ],
  email: 'shaopeng (dot) wang (dot) 23fall@gmail (dot) com',
  phone: '(+1) 404 203 5599',
  address: 'Atlanta, Georgia',
};