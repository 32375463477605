import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            Dedicated and results-driven Software Engineer with a proven track record of delivering innovative solutions in data infrastructure and engineering.
            Adept at orchestrating complex systems, automating processes, and optimizing data pipelines.
            Excels in cross-functional collaboration and thrives in dynamic, high-impact environments. 
            Eager to leverage expertise in Cloud Computing, Distributed Systems, and Agile Development to drive success for a forward-thinking organization.

          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer II</h3>
              <div className="subheading mb-3">Ninja Van</div>
              <p>
                <ul>
                  <li>
                    Designed and developed data warehouse pipelines and tables using Spark 2.0, providing 
                    accurate and timely data for all departments in the region. Built new data warehouse tables to 
                    support analytics and provide a single source of truth on Operational performance regionally.<br></br>
                  </li>
                  <li>
                    Scheduled, monitored and troubleshot data warehouse jobs to ensure accuracy of data and timeliness of all reports.<br></br>
                  </li>
                  <li>
                  Optimized jobs and removed bottlenecks to improve the efficiency of the end-to-end data warehouse pipeline.<br></br>
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">July 2021 - August 2023</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Internship</h3>
              <div className="subheading mb-3">JobKred</div>
              <p>
                <ul>
                  <li>
                    Developed and maintained a data pipeline of large-scale data indexing. 
                    Calculated correlations between job title and relevant job skills based on real user profiles.<br></br>
                  </li>
                  <li>
                    Explored new hypotheses, built deep learning algorithms, and was responsible to maintain model quality over time.<br></br>
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">December 2020 - Febuary 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Internship</h3>
              <div className="subheading mb-3">Human Managed</div>
              <p>
                <ul>
                  <li>
                    Applied data engineering and machine learning to correlate, enrich, 
                    and identify new ways of understanding the big data that were 
                    collected and streamed in Human Managed platform.<br></br>
                  </li>
                  <li>
                    Supported the creation of various use cases that will ultimately 
                    help end users to solve their business problems and make better decisions.<br></br>
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">December 2020 - Febuary 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Engineering Internship</h3>
              <div className="subheading mb-3">Philip Morris International Research Laboratories</div>
              <p>
                <ul>
                  <li>
                    Applied Random Forest algorithm to analyze parameters, predict 
                    optimal combination of instrument and improved experiment design efficiency.<br></br>
                  </li>
                  <li>
                    Developed Excel macro to improve efficiency in data analysis 
                    for experiment and strongly appreciated by colleagues.<br></br>
                  </li>
                  <li>
                    Implemented developed technical alternatives, assisted in 
                    design improvements for the conventional and Reduced Risk Products equipment 
                    / computerized systems.<br></br>
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">January 2020 - July 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Intern</h3>
              <div className="subheading mb-3">YARA International, Singapore Digital Hub</div>
              <p>
                <ul>
                  <li>
                    Developed a fertilizer recommendation tool, which provides non-dominated 
                    solutions based on farmers’ demand on nitrogen, potassium and phosphorous 
                    content, geographical location, soil content and season. 
                    Conducted a workshop about recommendation tool and received overwhelming feedback. <br></br>
                  </li>
                  <li>
                    Built a web framework, RESTful API, using AWS chalice and fine tuning with postman, 
                    reducing response time from beginning 30 seconds to 5 seconds. <br></br>
                  </li>
                  <li>
                    Built an image recognition model to detect rice disease 
                    with limited dataset and achieved 75% accuracy. <br></br>
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2019 - August 2019</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Georgia Institute of Technology</h3>
              <div className="subheading mb-3">Master of Science</div>
              <div>Computational Science &amp; Engineering</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2023 - May 2024</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Nanyang Technological University</h3>
              <div className="subheading mb-3">Bachelor of Engineering</div>
              <div>Chemical &amp; Biomolecular Engineering (Honors)</div>
              <p>GPA: 4.72</p>
              <ul>
                <li>Mathmatics: A+</li>
                <li>Discrete Mathmatics: A+</li>
                <li>Engineering Mathmatics: A+</li>
                <li>Cumputational Methods: A+</li>
                <li>Probability &amp; Introduction to Statistics: A+</li>
                <li>Statistics: A+</li>
                <li>Digital Logic: A+</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2017 - May 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">University of Wisconsin – Madison</h3>
              <div className="subheading mb-3">Global E3 Program</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2019 - December 2019</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Korea University</h3>
              <div className="subheading mb-3">International Summer Campus</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary"> June 2018 - August 2018</span>
            </div>
          </div>
        </div>
        
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        
        <div className="w-100">
          <h2 className="mb-5">Skills</h2>
          <div className="subheading mb-3">
            <i className="fas fa-laptop-code"></i>
            &nbsp;Programming Languages
          </div>
          <p>
            Python, Java, Go, SQL, R, JavaScript, HTML/CSS
          </p>
          <div className="subheading mb-3">
            <i className="fas fa-hammer"></i>
            &nbsp;Toolkit
          </div>
          <p>
            GCP, Kubernetes, Spark, Airflow, Kafka, Maxwell, MySQL, NewSQL,
            Delta Lake, PostgreSQL, MangoDB, Presto, Hadoop, Grafana, Ansible, Atlassian, Kibana, Elasticsearch,
            React, Redis, Git, Agile Development (Scrum), Cloud Computing, Distributed Systems
          </p>
          <div className="subheading mb-3">
            <i className="far fa-chart-bar"></i>
            &nbsp; Data Analytics
          </div>
          <p>
            Numpy, Pandas, Matplotlib, Scikit-Learn, Keras
          </p>
          <div className="subheading mb-3">
            <i className="fas fa-pencil-ruler"></i>
            &nbsp; Design
          </div>
          <p>
            Photoshop, Adobe Bridge, Autodesk Fusion 360
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Awards &amp; Certifications</h2>
          <div className="subheading mb-3">
            <i className="fas fa-graduation-cap"></i>
            &nbsp; NTU Science and Engineering Undergraduate Scholarship
          </div>
          <p>
            The NTU Science and Engineering Undergraduate Scholarship is 
            awarded to outstanding applicants from the Asian countries.
          </p>        
          <div className="subheading mb-3">
            <i className="fas fa-graduation-cap"></i>
            &nbsp; Dean’s List, School of Chemical &amp; Biomedical Engineering AY18-19
          </div>
          <p>
            Dean's list is awarded to the top 5% student of the cohort, 
            subject to attaining a minimum YGPA of 4.50.
          </p>        
          <div className="subheading mb-3">
            <i className="fas fa-university"></i>
            &nbsp; Machine Learning &amp; Data Science Bootcamp 2021
          </div>      
          <div className="subheading mb-3">
            <i className="fab fa-linkedin"></i>
            &nbsp; Learning Linux Command Line
          </div>
          <div className="subheading mb-3">
            <i className="fab fa-linkedin"></i>
            &nbsp; SQL Essential Training
          </div>
          <div className="subheading mb-3">
            <i className="fab fa-linkedin"></i>
            &nbsp; JavaScript Essential Training
          </div>     
          </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            Besides data analytics, I enjoy nighttime running, photography 
            and chinese calligraphy. However, now, during the weekend, I usually either 
            enjoy a meal with my girlfriend <i class="far fa-smile-wink"></i> or play game <i class="fas fa-frown"></i>...
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;